<template>
  <div>
    <loader :loading="loading"/>

    <page-header :title="`Novo Cliente`">
      <template v-slot:breadcrumbs>
        <li>Clientes</li>
        <li>Novo</li>
      </template>
    </page-header>

    <div class="card bg-white">
      <div class="card-body p-4">
        <span class="card-subtitle text-primary small fw-bold">Dados da Empresa/Corretor</span>
        <form>
          <div class="row g-2 mt-2">
            <div class="col-2">
              <label class="form-label">Cod. Vintage</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.external_id.$model"
                  :class="{ 'is-invalid' : $v.payload.external_id.$error }"
                  :disabled="$route.params.id"
              />
              <div class="invalid-feedback" v-if="$v.payload.external_id.$error">Valor maior que 999</div>
            </div>

            <div class="col-2">
              <label class="form-label">Tipo de pessoa</label>
              <select
                  class="form-select form-select-sm"
                  v-model.trim="$v.payload.person_type.$model"
                  :class="{ 'is-invalid' : $v.payload.person_type.$error }"
              >
                <option value="F">Física</option>
                <option value="J">Jurídica</option>
              </select>
            </div>

            <div class="col-2">
              <label class="form-label">{{ payload.person_type === 'J' ? 'CNPJ' : 'CPF' }}</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model.trim="$v.payload.document.$model"
                  v-mask="payload.person_type === 'F' ? '###.###.###-##' : '##.###.###/####-##'"
                  :class="{ 'is-invalid' : $v.payload.document.$error }"
                  @blur="checkDocumentIsUnique"
              />
              <div class="invalid-feedback" v-if="!$v.payload.document.document && $v.payload.document.$model">Documento
                inválido
              </div>

              <div class="invalid-feedback" v-if="!$v.payload.document.unique && $v.payload.document.$model">
                Documento já cadastrado
              </div>
            </div>

            <div class="col-6">
              <label class="form-label">Nome</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.name.$model"
                  :class="{ 'is-invalid' : $v.payload.name.$error }"
              />
            </div>
          </div>

          <div class="row g-2 mt-1">
            <div class="col-2">
              <label class="form-label">CEP</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.zip_code.$model"
                  v-mask="'#####-###'"
                  @blur="getAddress"
                  :class="{ 'is-invalid' : $v.payload.zip_code.$error }"
              />
            </div>

            <div class="col-1">
              <label class="form-label">UF</label>
              <select
                  class="form-select form-select-sm"
                  v-model="$v.payload.uf.$model"
                  :class="{ 'is-invalid' : $v.payload.uf.$error }"
              >
                <option value="">Selecione....</option>
                <option v-for="state in states" :key="state.item" :value="state.item">{{ state.uf }}</option>
              </select>
            </div>

            <div class="col-3">
              <label class="form-label">Cidade</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.city.$model"
                  :class="{ 'is-invalid' : $v.payload.city.$error }"
              />
            </div>

            <div class="col-4">
              <label class="form-label">Endereço</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.address.$model"
                  :class="{ 'is-invalid' : $v.payload.address.$error }"
              />
            </div>

            <div class="col-2">
              <label class="form-label">Número</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.number.$model"
                  :class="{ 'is-invalid' : $v.payload.number.$error }"
              />
            </div>

            <div class="col-3">
              <label class="form-label">Complemento</label>
              <input type="text" class="form-control form-control-sm" v-model="payload.complement"/>
            </div>

            <div class="col-4">
              <label class="form-label">Bairro</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.neighborhood.$model"
                  :class="{ 'is-invalid' : $v.payload.neighborhood.$error }"
              />
            </div>

            <div class="col-3">
              <label class="form-label">Whatsapp</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="payload.whatsapp"
                  v-mask="['(##) #####-####', '(##) ####-####']"
              />
            </div>

            <div class="col-2">
              <label class="form-label">Telefone</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="payload.phone"
                  v-mask="['(##) #####-####', '(##) ####-####']"
              />
            </div>

            <div class="col-3">
              <label class="form-label">E-mail</label>
              <input
                  type="email"
                  class="form-control form-control-sm"
                  v-model="$v.payload.email.$model"
                  :class="{ 'is-invalid' : $v.payload.email.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.payload.email.email">E-mail Inválido</div>
            </div>

            <div class="col-4">
              <label class="form-label">Website</label>
              <input
                  type="email"
                  class="form-control form-control-sm"
                  v-model="$v.payload.website.$model"
                  :class="{ 'is-invalid' : $v.payload.website.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.payload.website.email">Url Inválida</div>
            </div>

            <div class="col-2">
              <label class="form-label">CRECI</label>
              <input
                  type="email"
                  class="form-control form-control-sm"
                  v-model="payload.creci"
              />
            </div>
          </div>

          <hr class="my-4"/>

          <div class="d-flex align-items-center gap-4 mt-2 mb-3">
            <div>
              <span class="card-subtitle text-primary small fw-bold">Logo</span>

              <div class="d-flex mt-3">
                <div>
                  <input
                      type="file"
                      class="form-control form-control-sm d-none"
                      @change="onFileChange"
                      accept="image/*"
                      ref="file"
                  >
                </div>
                <div>
                  <img
                      :src="preview ? preview : require(`@/assets/img/placeholder.jpg`)"
                      width="250px"
                      class="rounded-3 border border-primary p-2"

                  />

                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <button class="btn btn-primary" @click.prevent="$refs.file.click()"><i class="la la-upload" /> Selecione</button>
                    <button class="btn btn-danger" @click.prevent="removeLogo"><i class="la la-trash" /> Remover</button>
                  </div>
                </div>
              </div>
            </div>

            <div>

              <span class="card-subtitle text-primary small fw-bold">Marca d´água</span>


              <div class="d-flex mt-3">
                <div>
                  <input
                      type="file"
                      class="form-control form-control-sm d-none"
                      @change="onWatermarkChange"
                      accept="image/*"
                      ref="watermark"
                  >
                </div>
                <div>
                  <img
                      :src="previewWatermark ? previewWatermark : require(`@/assets/img/placeholder.jpg`)"
                      width="250px"
                      class="rounded-3 border border-primary p-2"

                  />

                  <div class="d-flex align-items-center justify-content-between mt-3">
                    <button class="btn btn-primary" @click.prevent="$refs.watermark.click()"><i class="la la-upload" /> Selecione</button>
                    <button class="btn btn-danger" @click.prevent="removeWatermark"><i class="la la-trash" /> Remover</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="my-4" v-if="!$route.params.id"/>

          <span v-if="!$route.params.id"
                class="card-subtitle text-primary small fw-bold">Dados do Usuário Administrador</span>

          <div class="row g-2 mt-2 mb-3" v-if="!$route.params.id">
            <div class="col-4">
              <label class="form-label">Nome do usuário</label>
              <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="$v.payload.userName.$model"
                  :class="{ 'is-invalid' : $v.payload.userName.$error }"
              />
            </div>

            <div class="col-4">
              <label class="form-label">E-mail do usuário</label>
              <input
                  type="email"
                  class="form-control form-control-sm"
                  v-model="$v.payload.userEmail.$model"
                  :class="{ 'is-invalid' : $v.payload.userEmail.$error }"
              />
              <div class="invalid-feedback" v-if="!$v.payload.userEmail.email">E-mail Inválido</div>
            </div>
          </div>

          <hr class="my-4" v-if="!$route.params.id"/>

          <div v-if="!$route.params.id">
            <span class="card-subtitle text-primary fw-bold small">Configurações do Subsee</span>
            <div class="row g-2 mt-2 mb-3">
              <div class="col-3">
                <label class="form-label">Situação</label>
                <select
                    class="form-select form-select-sm"
                    v-model="$v.payload.status.$model"
                    :class="{ 'is-invalid' : $v.payload.status.$error }"
                >
                  <option :value="4">Trial 30 dias</option>
                  <option :value="1">Contrato ativo</option>
                </select>
                <div class="invalid-feedback" v-if="!$v.payload.status.required">Campo obrigatório</div>
              </div>
            </div>
            <hr class="my-4"/>

            <span class="card-subtitle text-primary fw-bold small">Configuração do Portal Sub100</span>
            <div class="row g-2 mt-2 mb-3">
              <div class="col-3">
                <label class="form-label">Tipo de contrato</label>
                <select
                    class="form-select form-select-sm"
                    v-model="payload.unlimited"
                    @change="() => {
                    payload.total_properties = null;
                  }"
                >
                  <option :value="false">Limitado</option>
                  <option :value="true">Ilimitado</option>
                </select>
              </div>

              <div class="col-4" v-if="!payload.unlimited">
                <label class="form-label">Quantidade de Imóveis contratados</label>
                <input
                    type="number"
                    step="1"
                    class="form-control form-control-sm"
                    v-model="$v.payload.total_properties.$model"
                    :class="{ 'is-invalid' : $v.payload.total_properties.$error }"
                />
                <div class="invalid-feedback" v-if="!$v.payload.total_properties.required">Campo obrigatório</div>
              </div>
            </div>

            <div class="row g-2 mt-2 mb-3">

              <div class="col-7">
                <table class="table table-sm table-bordered">
                  <tbody>
                  <tr>
                    <td class="text-center small fw-bold text-primary">Configuração do contrato</td>
                    <td class="text-center fw-bold">Super Destaques</td>
                    <td class="text-center fw-bold">Destaques</td>
                  </tr>
                  <tr>
                    <td class="text-center">Lançamentos</td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.super_highlights.launch"
                          placeholder="Nenhum"
                      >
                    </td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.highlights.launch"
                          placeholder="Nenhum"
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">Venda</td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.super_highlights.sale"
                          placeholder="Nenhum"
                      >
                    </td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.highlights.sale"
                          placeholder="Nenhum"
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">Locação</td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.super_highlights.rental"
                          placeholder="Nenhum"
                      >
                    </td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.highlights.rental"
                          placeholder="Nenhum"
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">Temporada</td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.super_highlights.season"
                          placeholder="Nenhum"
                      >
                    </td>
                    <td>
                      <input
                          type="number"
                          step="1"
                          min="0"
                          class="form-control form-control-sm border-0 text-center"
                          v-model="payload.highlights.season"
                          placeholder="Nenhum"
                      >
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>

        <div class="d-flex align-items-center justify-content-end">
          <button class="btn btn-primary" @click="handleSave">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "@/components/shared/loader.vue";
import PageHeader from "@/components/PageHeader.vue";
import {mask} from 'vue-the-mask'
import {mapActions, mapState} from "vuex";
import {email, required, requiredIf, url} from "vuelidate/lib/validators";
import {cnpj, cpf} from 'cpf-cnpj-validator';
import axios from "axios";

export default {
  name: 'add-client',
  components: {PageHeader, Loader},
  props: {},
  directives: {mask},
  data() {
    return {
      loading: false,
      preview: null,
      previewWatermark: null,
      document_unique: true,
      payload: {
        person_type: 'J',
        document: null,
        name: null,
        zip_code: null,
        uf: null,
        city: null,
        address: null,
        number: null,
        neighborhood: null,
        email: null,
        logo_url: null,
        watermark_url: null,
        website: null,
        userName: null,
        userEmail: null,
        whatsapp: null,
        phone: null,
        external_id: null,
        complement: null,
        total_properties: null,
        unlimited: false,
        creci: null,
        status: null,
        highlights: {
          launch: null,
          sale: null,
          rental: null,
          season: null
        },
        super_highlights: {
          launch: null,
          sale: null,
          rental: null,
          season: null
        }
      }
    }
  },
  validations() {
    return {
      payload: {
        person_type: {required},
        document: {
          required,
          document: function (val) {
            return this.payload.person_type === 'J' ? cnpj.isValid(val) : cpf.isValid(val)
          },
          unique: function (val) {
            return this.document_unique === true;
          }
        },
        name: {required},
        zip_code: {required},
        uf: {required},
        city: {required},
        address: {required},
        number: {required},
        neighborhood: {required},
        email: {required, email},
        status: {
          required: requiredIf(function () {
            return !this.$route.params.id
          }),
        },
        userName: {
          required: requiredIf(function () {
            return !this.$route.params.id
          })
        },
        userEmail: {
          required: requiredIf(function () {
            return !this.$route.params.id
          }),
          email
        },
        external_id: {
          max: function (val) {
            if (!val || this.$route.params.id) {
              return true;
            }
            return parseInt(val) <= 999
          }
        },
        total_properties: {
          required: requiredIf(function () {
            return !this.payload.unlimited && !this.$route.params.id
          })
        },
        website: {
          url
        }
      }
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      await this.getOneById(this.$route.params.id).then(response => {
        const person = response;

        this.payload.person_type = person.person_type;
        this.payload.name = person.name;
        this.payload.document = person.document;
        this.payload.address = person.address;
        this.payload.city = person.city;
        this.payload.uf = person.state_id;
        this.payload.number = person.number;
        this.payload.neighborhood = person.neighborhood;
        this.payload.zip_code = person.zip_code;
        this.payload.complement = person.complement;
        this.preview = response.logo_url;
        this.previewWatermark = response.watermark_url;
        this.payload.external_id = person.external_id;
        this.payload.email = person.email;
        this.payload.whatsapp = person.whatsapp;
        this.payload.phone = person.phone;
        this.payload.website = person.website;
        this.payload.creci = person.creci;
      });
    }
  },
  computed: {
    ...mapState('Locations', ['states']),
  },
  methods: {
    ...mapActions('Locations', ['getAddressByCep']),
    ...mapActions('Clients', ['getOneById', 'save']),
    async getAddress(zip_code) {
      this.loading = true;
      await this.getAddressByCep(zip_code.target.value).then(response => {
        if (response) {
          this.payload.uf = response.uf;
          this.payload.address = response.address;
          this.payload.neighborhood = response.neighborhood;
          this.payload.city = response.city
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    async checkDocumentIsUnique() {
      this.document_unique = true;
      await axios.post('/admin/company/find-by-document', {
        document: this.payload.document
      }).then(response => {
        if (response.data.document) {
          this.document_unique = false;
        }
      });
    },
    onFileChange(e) {
      this.payload.logo = null;
      this.payload.logo_url = null;
      this.preview = null;
      const file = e.target.files[0];
      this.preview = URL.createObjectURL(file);
      this.payload.logo = file;
      this.$forceUpdate();
    },
    onWatermarkChange(e) {
      this.payload.whatermark = null;
      this.payload.watermark_url = null;
      this.previewWatermark = null;
      const file = e.target.files[0];
      this.previewWatermark = URL.createObjectURL(file);
      this.payload.watermark = file;
      this.$forceUpdate();
    },
    removeWatermark() {
      this.previewWatermark = null;
      this.payload.watermark_url = null;
      this.payload.removeWatermark = true;
    },
    removeLogo() {
      this.preview = null;
      this.payload.logo_url = null;
    },
    create() {
      return this.save(this.$serialize(this.payload))
    },
    async edit() {
      const formData = this.$serialize(this.payload);
      formData.append('_method', 'PUT');
      return axios.post(`/admin/company/${this.$route.params.id}`, formData)
    },
    async handleSave() {
      this.$v.$touch();

      if (this.$v.payload.$invalid) {
        return;
      }

      this.loading = true;

      try {
        if (!this.$route.params.id) {
          await this.create().then(() => {
            this.$router.push('/clientes');
          }).finally(() => {
            this.loading = false;
          })
        } else {
          await this.edit().then(() => {
            this.$router.push('/clientes');
          }).finally(() => {
            this.loading = false;
          })
        }
      } catch (error) {
        this.$swal.fire({
          title: 'Erro!',
          text: 'Ocorreu um erro ao salvar o cliente:' + error.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
